<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userFullname}}
            </p>
            <span class="user-status">
              {{userRole}}
            </span>
          </div>
  <!-- Notifications Panel -->
  <!-- <notification-dropdown :notifications="userNotifications" /> -->
          <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          /> -->
          <div class="ml-1">
            <avatar :username="userFullname.length ? userFullname : 'WB OstiSistemi'" />
          </div>
        </template>

        <b-dropdown-item
                  @click="userProfile"
                  link-class="d-flex align-items-center"
        >
          <b-link
            class="nav-link"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>Profile</span>
          </b-link>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item> -->

<!-- d-none css class to display:none small screen -->
        <dark-Toggler style="width:100%;" class="d-lg-block" />

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          @click="userLogout"
          link-class="d-flex align-items-center"
        >
          <b-link
            class="nav-link"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Logout</span>
          </b-link>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Avatar from 'vue-avatar'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  data() {
    return {
      userNotifications: [],
    }
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    // BAvatar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    Avatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userFullname() {
      return localStorage.fullname ? localStorage.fullname : ''
    },
    userRole() {
      return localStorage.role ? localStorage.role : ''
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    userLogout() {
      localStorage.clear()
      this.$router.push('/')
      // this.$http.delete('/v1/auth/logout').then(res => {
      //     localStorage.clear()
      //     $router.push('/')
      //   })
    },
    userProfile() {
      if (this.currentRouteName != 'profile') this.$router.push({ name: 'profile' })
    },
  },
}
</script>
