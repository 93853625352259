export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Segnalazioni',
        route: { name: 'all-segnalazioni', params: { filter: null } },
        icon: 'AlignLeftIcon',
      },
      {
        title: 'Aperte',
        route: { name: 'open-segnalazioni', params: { filter: { closedAt: null } } },
        icon: 'BookOpenIcon',
      },
      {
        title: 'Nuove',
        route: { name: 'new-segnalazioni', params: { filter: { answerAt: null } } },
        icon: 'FilePlusIcon',
      },
      {
        title: 'Aspetta risposta',
        route: { name: 'awaits-segnalazioni', params: { filter: { awaitAnswer: true } } },
        icon: 'MessageSquareIcon',
      },
      {
        title: 'Chiuse',
        route: { name: 'closed-segnalazioni', params: { filter: { closed: true } } },
        icon: 'XIcon',
      },
    ],
  },
  {
    header: 'Gestione'
  },
  {
    title: 'Utenti',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Dati aziendali',
    route: 'azienda',
    icon: 'FileTextIcon',
  },
  {
    title: 'Abbonamento',
    route: 'subscription',
    icon: 'PenToolIcon',
  },
  {
    header: 'Informazioni',
  },
  {
    title: 'FAQ',
    route: 'faq',
    target: '_blank',
    icon: 'InfoIcon',
  },
  {
    title: 'Tutorial',
    route: 'tutorial',
    icon: 'BookIcon',
  },
]
